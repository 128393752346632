.navbar {
    margin-left: 15px !important;
    padding-left: 15px;
}

.navbar img {
    width: auto;
    height: 50px
}

.header-container .navbar-brand {
    display: flex;
    align-items: center;
    font-size: 35px;
}

.header-container .navbar-brand img {
    margin-right: 10px;
}

.header-slogan {
    font-size: 0.5em;
    color: #fff;
    margin-top: -10px;
}