html,
body,
#root {
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.content {
    flex: 1;
}